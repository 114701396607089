.tablepding td{

      padding-top: 2px;
      padding-bottom: 2px;
}
.tablebg {
    color: red;
    font-weight: bold;
    background: #EECFCF;
    cursor: pointer;
}


.hoverTable tr:hover {
      background-color: #D4D4D4;
      transform: scale(1.01);
}

.siiRecordsTable,.siiRecordsTh,.siiRecordsTd {
      border: 1px solid black;
      border-collapse: collapse;
      padding: 5px;
}

.siimodal {
      max-width: 1024px !important;
}

.modal-body {
      padding: 10px 10px !important;
}