$accent: #00BCD4;
$primary: #212121;
$secondary: #727272;

$scoreRed: #F44336;
$scoreYellow: #FFEB3B;
$scoreGreen: #4CAF50;

.password {
  display: block;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: $secondary;



  &__show {
    cursor: pointer;
    position: absolute;
    bottom: 11px;
    height: 16px;
    right: 0;
    color: black;
    padding-right: 12px;
    // border-radius: 4px;
    font-weight: 700;
    font-size: 1.5rem;
  }


}
