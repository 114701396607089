:root {
  --primary-dark: #181818;
  --secondary-dark: #303030;
  --light-dark: #262626;
  --primary-white: #e9e3e9;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 5rem;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/*  setting menu css */

.text-hover:hover {
  background-color: #e7e8ec !important;
}

.listStyle {
  list-style: none;
  background-color: #ffffff;
  padding: 5px 20px;
}

.cardBoxShadow {
  /* box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
    0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1) !important; */
  box-shadow: none !important;
}

.activeSetting {
  background-color: #c3c7cc !important;
}

.nav-wrapper ul .nav-item:last-child {
  position: absolute;
  bottom: 0px;
  border-top: 1px solid rgba(255, 255, 255, .5) !important;
  height: 3.78rem;
}


.btn-primary,
.btn-accent {
  background-color: #2E1F9D !important;
  border-color: #7a7a7a54 !important;
  color: #fff !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
}




/* over dark theme */

@media screen and (max-width: 480px) {
  .auth-inner {
    width: 400px;
    padding: 40px !important;
    margin-top: -53px !important;

  }



  /* .svg_container__2T9PB {
    height: 87vh !important;
  } */

  #main_page_logo {
    height: 70px !important;
  }
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: auto !important;
  }
}


@media screen and (max-width: 760px) {
  /* .table-responsive {
    display: block !important;
  } */


  /* .container {
    max-width: 100% !important;
  } */
}

@media screen and (max-width: 420px) {
  .auth-inner {
    width: 340px;
    padding: 24px !important;

  }

  #main_page_logo {
    height: 70px !important;
  }
}


.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__input{
  width: 100%;
  height: auto !important;
  padding: .4375rem .75rem !important;
  font-size: .8125rem !important;
  line-height: 1.5 !important;
  background-color: #fff !important;
  border: 1px solid #e1e5eb !important;
  font-weight: 300 !important;
  will-change: border-color,box-shadow !important;
  border-radius: .25rem !important;
  box-shadow: none !important;
  transition: box-shadow 250ms cubic-bezier(.27,.01,.38,1.06),border 250ms cubic-bezier(.27,.01,.38,1.06) !important;
}

.react-autosuggest__input:focus{
  outline: none !important;
  border-color: #007bff !important;
  box-shadow: 0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.125rem rgba(0,0,0,.06) !important; 
}