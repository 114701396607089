.container {
  height: 100vh;
  width: 100%;
  /* background: #5B9EBF; */
  background: linear-gradient(to right, #DE1D0D, #2E1F96);
  /* background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%); */
  /* background: linear-gradient(to bottom, #2d3436); */
  /* background-color: #4D4D4d; */
  /* background-color: #485461; */
  /* background-image: linear-gradient(147deg, #000000 10%, #2c3e50 74%); */
  background-position: center;
  /* background-color: #9dc5c3; */
  /* background-image: linear-gradient(315deg, #9dc5c3 0%, #5e5c5c 74%); */
  position: absolute;
  overflow: hidden;
}

.svg_container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.svg_container svg {
  display: block;
}

.svg_container svg path {
  fill: #fff;
}

.svg_container svg path#curve1 {
  opacity: 0.6;
}

.svg_container svg path#curve2 {
  opacity: 0.4;
}

.mtt {
  position: fixed;
  bottom: 10px;
  right: 20px;
  color: #999;
  text-decoration: none;
  font-family: sans-serif;
}

.mtt span {
  color: #e74c3c;
}

.mtt:hover {
  color: #666;
}

.mtt:hover span {
  color: #c0392b;
}
